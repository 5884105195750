/** @jsx jsx */
import { Link } from "gatsby"
import { Flex, Box, jsx } from "theme-ui"
import { useLocation } from "@reach/router"
export default function NavItem({ to, ...props }) {
  const location = useLocation()
  const isCurrentLocation = location.pathname === to
  return (
    <Box
      as={`li`}
      sx={{
        "&::marker": {
          fontSize: "1rem",
          fontWeight: "bold",
          content: isCurrentLocation ? "'|'" : "'—'",
          color: isCurrentLocation ? "green.6" : null,
        },
      }}
    >
      <Flex
        as={Link}
        to={to}
        sx={{
          padding: 2,
          textDecoration: "none",
          fontWeight: "600",
          fontSize: `0.8rem`,
          color: "gray.9",
        }}
        {...props}
      />
    </Box>
  )
}
