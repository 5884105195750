import React, { useState } from "react"
import { Box, Flex } from "theme-ui"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Logo from "../../images/logo.svg"
import Navigation from "../Navigation/Navigation"
const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Box
        as="header"
        sx={{
          backgroundColor: `white`,
          // marginBottom: 4,
          boxShadow: "default",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <Flex
          sx={{
            margin: `0 auto`,
            maxWidth: 960,
            py: 2,
            px: 3,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            as={Link}
            to="/"
            sx={{
              color: `text`,
              textDecoration: `none`,
            }}
          >
            <Box
              as={`img`}
              sx={{
                height: 8,
                width: "40",
                margin: 0,
                padding: 0,
              }}
              width="160px"
              height="32px"
              src={Logo}
              title={`Logo`}
              loading={`lazy`}
              alt={siteTitle}
            ></Box>
          </Box>
          <Box
            as={`button`}
            aria-label={`meny`}
            css={{
              fontSize: "100%",
              border: 0,
              padding: 2,
              backgroundColor: "transparent",
            }}
            sx={{
              height: [8, null, 8],
              width: [8, null, 8],
              color: isOpen ? "green.5" : "gray.9",
            }}
            onClick={e => {
              setIsOpen(e => !e)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 8h16M4 16h16"
              />
            </svg>
          </Box>
        </Flex>
        <Navigation isOpen={isOpen} />
      </Box>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
