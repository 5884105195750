/** @jsx jsx */
import { jsx } from "theme-ui"
import { motion } from "framer-motion"
import { useBodyScrollLock } from "@weahead/react-customizable-modal"

export default function Backdrop({ children }) {
  useBodyScrollLock()
  return (
    <motion.div
      key={`backdrop`}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", stiffness: 100, mass: 0.1 }}
      sx={{
        // backgroundColor: theme => `${theme.colors.gray[9]}`,
        backgroundColor: "#1A202C80",
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {children}
    </motion.div>
  )
}
