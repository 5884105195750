/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { Box, jsx } from "theme-ui"
import { motion } from "framer-motion"
import NavItem from "./NavItem"
import PageContainer from "../PageContainer"
export default function Navigation({ isOpen }) {
  const { posts } = useStaticQuery(graphql`
    query NavigationQuerie {
      posts: allMdx(sort: { order: DESC, fields: fileAbsolutePath }) {
        nodes {
          frontmatter {
            slug
            title
          }
          id
        }
      }
    }
  `)

  const variants = {
    open: {
      opacity: 1,
      height: "auto",
    },
    closed: { opacity: 1, height: 0 },
  }

  return (
    <motion.nav
      sx={{ backgroundColor: "gray.1", overflow: "hidden" }}
      variants={variants}
      initial={"closed"}
      transition={{ type: "spring", stiffness: 100, mass: 0.1 }}
      animate={isOpen ? "open" : "closed"}
    >
      <PageContainer>
        <Box as={`ul`} sx={{ padding: 0 }} role="list">
          {posts.nodes.map((post, index) => {
            return (
              <NavItem key={index} to={post.frontmatter.slug}>
                {post.frontmatter.title}
              </NavItem>
            )
          })}
        </Box>
      </PageContainer>
    </motion.nav>
  )
}
