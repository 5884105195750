import React from "react"
import { Box } from "theme-ui"
export default function PageContainer({ children }) {
  return (
    <Box
      sx={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: 3,
        paddingTop: 0,
      }}
    >
      {children}
    </Box>
  )
}
