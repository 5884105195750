import React from "react"
import { Box } from "theme-ui"

export default function Footer() {
  return (
    <Box sx={{ backgroundColor: "gray.1", padding: 4 }}>
      BOLANFAQ.SE © ALL RIGHTS RESERVED.
      <Box as={`ul`} sx={{}}>
        <Box as={`li`} sx={{}}>
          <Box
            as={`a`}
            sx={{ color: "gray.7" }}
            href={`mailto:kontakt@bolanfaq.se`}
          >
            Kontakta
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
