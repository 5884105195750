/** @jsx jsx */
import { useState } from "react"
import { useCookieConsents } from "../../hooks/cookie-consents-provider"
import { Box, Button, Flex, jsx, Text } from "theme-ui"
import cookie from "../../images/cookie.svg"
import Backdrop from "./Backdrop"
import { ModalPortal } from "@weahead/react-customizable-modal"
import { motion, AnimatePresence } from "framer-motion"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useLocation } from "@reach/router" // this helps tracking the location
import Cookies from "js-cookie"
export default function CookieConsent() {
  // const modalRef = useTrapFocus()
  const cookieConsents = useCookieConsents()
  const [hasAnswerdCookieNotice, setHasAnswerdCookieNotice] = useState(
    Cookies.get("gatsby-gdpr-google-analytics") === "true"
  )
  // const hasAnswerdCookieNotice =
  //   Cookies.get("gatsby-gdpr-google-analytics") == "true"
  const location = useLocation()
  console.log(Cookies.get("gatsby-gdpr-google-analytics"))
  return (
    <AnimatePresence>
      {hasAnswerdCookieNotice ? null : (
        <ModalPortal id={`cookie-consent`}>
          <Backdrop>
            <motion.div
              key={`cookieconsent`}
              initial={{ opacity: 1, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{ type: "spring", stiffness: 100, mass: 0.1 }}
              // ref={modalRef}
              sx={{
                margin: 4,
                padding: 3,
                borderRadius: "lg",
                maxWidth: "800px",
                boxShadow: "default",
                backgroundColor: "white",
              }}
            >
              <Flex
                sx={{
                  flexDirection: ["column", null, "row"],
                  justifyContent: "center",
                }}
              >
                <Box
                  as={`img`}
                  src={cookie}
                  width={`112px`}
                  height={`192px`}
                  alt={`cookie notice illustration`}
                  sx={{ width: "100px", height: "100px", alignSelf: "center" }}
                />
                <Box>
                  <Text sx={{ fontWeight: "bold" }}>
                    Cookies på bolånFAQ.se
                  </Text>
                  <Box as={`p`} sx={{ marginBottom: 3, fontSize: 0 }}>
                    Vi använder google analytics för statistik, inget samlas in
                    i marknadsförings syfte. Vissa av länkarna är affiliate
                    länkar, som hjälper oss hålla denna sida updaterad.
                  </Box>
                </Box>
              </Flex>
              <Flex sx={{ gap: "20px", justifyContent: "flex-end" }}>
                <Button
                  variant={"outline"}
                  type="button"
                  onClick={() => cookieConsents.add("gatsby-gdpr-reject")}
                >
                  Nej tack
                </Button>
                <Button
                  variant={"simple"}
                  sx={{ width: "100px" }}
                  type="button"
                  onClick={() => {
                    Cookies.set("gatsby-gdpr-google-analytics", "true")
                    setHasAnswerdCookieNotice(true)
                    setTimeout(() => initializeAndTrack(location), 1000)
                  }}
                >
                  Ok
                </Button>
              </Flex>
            </motion.div>
          </Backdrop>
        </ModalPortal>
      )}
    </AnimatePresence>
  )
}
